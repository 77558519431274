<template>
	<div class="cbox">

		<div id="mychart" style="width: 100%; height: 800px; border: 1px solid red;"></div>

	</div>
</template>

<script>
	export default {
		name: 'stuDept',
		props: ["pmid"],
		components: {},
		data() {
			return {

			}


		},


		mounted() {


			let test = require("./test2.js")
			let testData = require("./test.js")
			let graph = test.default
			testData = testData.default

			//先找ip
			let ipDic = {},
				ipArr = [],
				sipArr = [],
				dipArr = [];
			let idx = 0;
			for (let item of testData.nodes) {
				let symbolSize = 10 * Math.random()
				idx++;

				let node = {
					id: idx + "",
					name: item.sipAddr + ":" + item.sport,
					symbolSize: symbolSize,
					value: symbolSize,
					item: item,
					category: 0,

				}
				sipArr.push(node)

				if (!ipDic[node.item.sipAddr]) {
					ipDic[node.item.sipAddr] = ipArr.length + 1
					ipArr.push({
						name: node.item.sipAddr
					})
				}

				idx++
				let symbolSize2 = 10 * Math.random()

				let node2 = {
					id: idx + "",
					name: item.dipAddr + ":" + item.dport,
					symbolSize: symbolSize2,
					value: symbolSize2,
					item: item,
					category: 0
				}
				dipArr.push(node2)
				if (!ipDic[node2.item.dipAddr]) {
					ipDic[node2.item.dipAddr] = ipArr.length + 1
					ipArr.push({
						name: node2.item.dipAddr
					})
				}



			}

			for (let item of sipArr) { //分配分组
				item.category = ipDic[item.item.sipAddr] - 1
			}
			for (let item of dipArr) { //分配分组
				item.category = ipDic[item.item.dipAddr] - 1
			}
			let nodeData = sipArr.concat(dipArr)

			console.log('ipArr');
			console.log(nodeData);

			let links = []
			for (let i = 0; i < sipArr.length; i++) {
				links.push({
					"source": sipArr[i].id,
					"target": dipArr[i].id
				})
			}
			
			nodeData=nodeData.sort((a,b)=>{return a.name-b.name})

			// graph.nodes.forEach(function (node) {
			//   node.label = {
			//     show: node.symbolSize > 30s
			//   };
			// });
			let option = {
				title: {
					text: '测试',
					subtext: '测试洒水',
					top: 'bottom',
					left: 'right'
				},
				tooltip: {
					   formatter: function (params) {
						   console.log(nodeData[params.dataIndex]);
						   let html =`<span>${nodeData[params.dataIndex].item.sipAddr}:${nodeData[params.dataIndex].item.sport}</span> --&gt; 
						   <span>${nodeData[params.dataIndex].item.dipAddr}:${nodeData[params.dataIndex].item.dport}</span>
						   `
						   return html
					   }
				},
				legend: [{
					data: ipArr.map(function(a) {
						return a.name;
					})
				}],
				animationDurationUpdate: 1500,
				animationEasingUpdate: 'quinticInOut',
				series: [{
						name: "一个列",
						type: 'graph',
						layout: 'circular',
						circular: {
							rotateLabel: true
						},
						data: nodeData || graph.nodes,
						links: links, //||graph.links,
						categories: ipArr,
						roam: true,
						label: {
							position: 'right',
							formatter: '{b}'
						},
						lineStyle: {
							color: 'source',
							curveness: 0.3
						}
					}

				]
			};
			this.$echarts.init(document.getElementById('mychart')).setOption(option);



		},
		methods: {

		}
	}
</script>

<style></style>